import {ossImg} from '../../../utils/oss'
import {getValue} from '../../../utils/view'

const CN = () => ({
  seo: {
    title: '拍机堂 - 万物新生',
    description: '拍机堂:万物新生旗下二手3C产品B2B交易平台, 打造行业交易和服务的基础设施.',
    keywords: '万物新生、万物新生 官网、爱回收、爱回收 官网、拍机堂、拍机堂 官网、拍拍、拍拍 官网'
  },
  banner: {
    logo: require('../../../assets/logo-business-paijitang.png'),
    name: '',
    position: '二手3C产品B2B交易平台',
    introduction: '打造行业交易和服务的基础设施'
  },
  breadcrumb: '首页/品牌业务/拍机堂',
  about: {
    cover: ossImg('/business/paijitang/paijitang.png'),
    infos: [
      [
        {
          type: 'h1',
          text: '打造商家生态系统与行业基础设施'
        },
        {
          type: 'subTitle',
          text: '传统行业面临的问题:商家缺乏质检和合理定价的能力，行业缺乏统一的标准导致流通周转效率低'
        },
        {
          type: 'description',
          text: '行业缺乏标准，交易周转效率低，交易层级多，商家分散。多年来，万物新生集团始终坚持自己的定位：科技驱动的供应链能力开放赋能平台。让数十万行业参与者都能做好以旧换新，让二手生意更轻松。'
        },
      ],
      [
        {
          hidePrefixIcon: false,
          type: 'h3',
          text: '拍机堂主要为国内广泛的手机电子产品买卖商家打造高效的二手交易平台。'
        },
        {
          type: 'subTitle',
          text: '拍机堂坚持以质检、定级、定价的技术作为驱动力，持续提升供应链能力、开放赋能全行业参与方。'
        }
      ],
      [
        {
          hidePrefixIcon: true,
          type: 'h3',
          text: '商品品类'
        },
        {
          type: 'icons',
          layoutType: 'splitHorizontal',
          list: [
            {
              icon: require('../../assets/business/Paijitang/icon-group-1.1.png'),
              name: '手机平板品类',
            },
            {
              icon: require('../../assets/business/Paijitang/icon-group-1.2.png'),
              name: '笔记本品类',
            },
            {
              icon: require('../../assets/business/Paijitang/icon-group-1.3.png'),
              name: '智能数码',
            },
          ]
        },
      ],
    ]
  },
  operate: {
    title: '高效的运营处置能力',
    cover: ossImg('/business/paijitang/1.png'),
    description: '拍机堂打造商家生态系统与行业基础设施，其运营体系保障平台货源足、品类全、价格优、周转快，实现货品跨区域流通，改变了过去二手3C产品传统的统货、现货、依赖经验的交易方式，极大提高了二手3C产品流通效率。',
    units: [
      {
        name: '区域级运营中心',
        unit: '个',
        value: '8'
      },
    ],
    tip: '注：截至 2024 年 12 月 31 日'
  },
  automation: {
    sectionName: '高精度自动化质检',
    description: '拍机堂通过自主研发的交易平台技术、大数据智能定价技术、自动化检测技术，实现自动化的高精度质检和交易流程，为行业制定标准，为商家赋能。',
    units: [
      {
        name: '质检标准',
        unit: '项',
        value: '39',
        sup: ''
      },
      {
        name: '质检等级',
        unit: '个',
        value: '36',
        sup: ''
      },
      {
        name: '手机质检精准度',
        unit: '',
        value: '99%',
        sup: ''
      },
    ],
    notes: ''
  },
  service: {
    sectionName: '超强服务力',
    cover: ossImg('/business/paijitang/services.png'),
    infos: [
      {
        title: '基础服务',
        description: '质检、定级、定价、竞拍'
      },
      {
        title: '增值服务',
        description: '维修、外观处理、即刻卖、物流、供应链金融、To C 销售'
      }
    ]
  },
  manage: {
    sectionName: '智能完善的后台管理',
    imageUrl: getValue({
      small: ossImg('/business/paijitang/smart-m.png'),
      large: ossImg('/business/paijitang/smart.png'),
    })
  },
  business: {
    sectionName: '商家的价值',
    left: {
      channel: '卖家',
      sections: [
        {
          title: '卖得快、回款快',
          description: '全国买家在线竞拍，保障足，买家质量保障'
        },
        {
          title: '卖得动、卖得掉',
          description: '大平台，回收行业领导者，多家合作企业，拥有海量用户流量'
        },
        {
          title: '卖得高',
          description: '卖家直通买家，没有中间商赚差价'
        }
      ]
    },
    right: {
      channel: '买家',
      sections: [
        {
          title: '品质好、有保障',
          description: '上千质检人员统一定级，放心大胆买'
        },
        {
          title: '全国货源',
          description: '手机、笔记本、平板电脑、相机数码等品类，数万sku支持'
        },
        {
          title: '售后好、维权方便',
          description: '买卖流程系统判断，问题机器退货'
        }
      ]
    },
  },
  swipers: [
    {
      title: '芜湖某手机零售小店',
      subTitle: '',
      list: [
        {
          name: '合作背景：',
          values: [
            '回收机器一部分卖给消费者，大部分二手机被压价卖给黄牛，主要利润依靠零售'
          ]
        },
        {
          name: '尝鲜初衷：',
          values: [
            '背靠大平台，交易有保障，无中间商赚差价'
          ]
        },
        {
          name: '目前现状：',
          values: [
            '月均发货100多台，平均成交价比本地交货价高20%，6个月时间比合作前多赚4万6，主要利润来源来自于拍机堂出货'
          ]
        },
        {
          name: '平台借力：',
          values: [
            '1.买家直接竞价拿货',
            '2.价格透明，无压价问题',
            '3.APP帮助收货价格支撑'
          ]
        }
      ],
      cover: ossImg('/business/paijitang/wuhu.png'),
    }
  ],
})

const US = () => ({
  seo: {
    title: 'PJT - ATRenew',
    description: 'PJT - ATRenew\'s B2B trading platform for pre-owned consumer electronics - infrastructure for transactions and services.',
    keywords: 'ATRenew、RERE、AiHuiShou、PJT、Paipai'
  },
  banner: {
    logo: require('../../../assets/logo-business-paijitang.png'),
    name: 'PJT Marketplace',
    position: 'B2B trading platform for pre-owned consumer electronics',
    introduction: 'Infrastructure for transactions and services'
  },
  breadcrumb: 'Home / Business / PJT Marketplace',
  about: {
    cover: ossImg('/business/paijitang/paijitang.png'),
    infos: [
      [
        {
          type: 'h1',
          text: 'Establish the infrastructure for transactions and services'
        },
        {
          type: 'subTitle',
          text: 'Today’s merchants lack quality inspection and pricing capabilities for their pre-owned devices. '
        },
        {
          type: 'description',
          text: 'The lack of industry standards, proliferation of intermediaries and a fragmented marketplace all contribute to suppress turnover of recycled devices. Over the years, ATRenew has redefined the industry with our value proposition as a pre-owned consumer electronics supply chain technology and service provider. We are committed to empowering business owners to implement trade-in services with ease.'
        },
      ],
      [
        {
          hidePrefixIcon: false,
          type: 'h3',
          text: 'PJT Marketplace is a B2B platform that enables efficient transactions among business owners.'
        },
        {
          type: 'subTitle',
          text: 'PJT Marketplace is founded on standards of high-quality inspection, grading, and pricing technology, continuously enhancing supply chain capabilities and broadening access for industry participants.'
        }
      ],
      [
        {
          hidePrefixIcon: true,
          type: 'h3',
          text: 'Categories'
        },
        {
          type: 'icons',
          layoutType: 'line',
          list: [
            {
              icon: require('../../assets/business/Paijitang/icon-group-1.1.png'),
              name: 'Mobile phones and tablets',
            },
            {
              icon: require('../../assets/business/Paijitang/icon-group-1.2.png'),
              name: 'Laptops',
            },
            {
              icon: require('../../assets/business/Paijitang/icon-group-1.3.png'),
              name: 'Other smart devices',
            },
          ]
        },
      ],
    ]
  },
  operate: {
    title: 'Highly efficient operation',
    cover: ossImg('/business/paijitang/1.png'),
    description: 'PJT Marketplace creates a previously nonexistent business ecosystem and key industry infrastructure. Its operational system guarantees the supply of high-quality products, varied categories, fair pricing and improved turnover. PJT Marketplace affords cross-regional goods circulation while elevating the standard for inventory management.',
    units: [
      {
        name: 'Regional operation centers',
        unit: '',
        value: '8'
      },
    ],
    tip: 'Note: As of December 31, 2024',
  },
  automation: {
    sectionName: 'Precise automated quality inspection',
    description: 'PJT Marketplace leverages its proprietary platform, data-driven pricing technology and automated inspection technology to facilitate a seamless and high-quality inspection and transaction process for users.',
    units: [
      {
        name: 'Inspection criteria',
        unit: '',
        value: '39',
        sup: '',
      },
      {
        name: 'Grading tiers',
        unit: '',
        value: '36',
        sup: '',
      },
      {
        name: 'Mobile phone inspection accuracy',
        unit: '',
        value: '99%',
        sup: ''
      },
    ],
    notes: ''
  },
  service: {
    sectionName: 'Industry-leading services',
    cover: ossImg('/business/paijitang/services.png'),
    infos: [
      {
        title: 'Basic services',
        description: 'Quality inspection, grading, pricing, auction'
      },
      {
        title: 'Value-added services',
        description: 'Device repair, exterior refurbishment, instant sale, logistics support, supply chain finance, 2C sales support'
      }
    ]
  },
  manage: {
    sectionName: 'Smart management system for users',
    imageUrl: getValue({
      small: ossImg('/business/paijitang/smart-m.png'),
      large: ossImg('/business/paijitang/smart.png'),
    })
  },
  business: {
    sectionName: 'Value to users',
    left: {
      channel: 'To sellers',
      sections: [
        {
          title: 'Larger market',
          description: 'Online bidding for buyers nationwide'
        },
        {
          title: 'Efficient',
          description: 'No more intermediaries'
        },
        {
          title: 'Sell with confidence',
          description: 'Industry leader with high sales volume and major partners'
        }
      ]
    },
    right: {
      channel: 'To buyers',
      sections: [
        {
          title: 'Quality assurance',
          description: 'Standardized grading across regional markets'
        },
        {
          title: 'Nationwide sourcing',
          description: 'A wide range of goods available across consumer electronics category'
        },
        {
          title: 'Buy with confidence',
          description: 'Digital platform ensures smooth transactions and after-sales service'
        }
      ]
    },
  },
  swipers: [
    {
      title: 'A mobile phone retail store in Wuhu',
      subTitle: '',
      list: [
        {
          name: 'Before:',
          values: [
            'This retailer sold the majority of its goods to pre-owned phone dealers at below market price and was unable to profit.'
          ]
        },
        {
          name: 'The PJT Advantage:',
          values: [
            'National brand, safeguarded transactions, no intermediaries'
          ]
        },
        {
          name: 'Now:',
          values: [
            'PJT has become this retailer’s primary distribution channel. Their average selling price (ASP) is 20% more than the local market price.'
          ]
        },
        {
          name: 'Platform advantage:',
          values: [
            'Auctions mean no more intermediaries,',
            'more transparency,',
            'and fair pricing.'
          ]
        }
      ],
      cover: ossImg('/business/paijitang/wuhu.png'),
    }
  ],
})

const data = {
  CN,
  US
}
export default data
