import { ossImg } from 'utils/oss'
import { getValue } from 'utils/view'

const CN = () => ({
  breadcrumb: '首页 / ESG / 绿色发展',
  seo: {
    title: 'ESG绿色发展 - 万物新生',
    keywords: '万物新生、万物新生 官网、爱回收、爱回收 官网、拍机堂、拍机堂 官网、拍拍、拍拍 官网',
    description: '万物新生通过回收与交易服务,延长电子产品的生命周期,从而推动消费电子生态系统的可持续发展,减少碳排放.',
  },
  banner: getValue({
    large: ossImg('/esg/overall-banner.png'),
    small: ossImg('/esg/esg-m-banner.png'),
  }),
  paragraphs: [
    {
      title: '绿色发展',
      content: [
        '万物新生通过对3C产品的回收和循环再利用，重新定义3C产品的生命周期，不断探索推动行业的可持续发展及低碳减排。',
        '本集团的商业模式打造了完整的“循环经济”样板，响应国家“十四五” 循环经济发展规划的政策方针，更通过技术和平台化的力量为国家规划的“双碳”目标做出力所能及的贡献。努力构建更绿色的供应链，努力成为领先的碳中和公司及碳足迹管理的行业标杆企业，努力通过供应链影响力辐射更多商业伙伴。',
      ],
      subTitle: '',
      units: []
    },
    {
      title: '绿色包装',
      content: [
        '万物新生将绿色基因根植于日常运营中，采购更加环保耐用的包装材料，积极推进物品包装的标准化、绿色化、循环化与减量化。',
      ],
      subTitle: '2023年回收利用',
      units: [
        {
          name: 'ToB 业务包裹填充物',
          unit: '吨',
          value: '24',
        },
        {
          name: 'ToC 业务包装材料约',
          unit: '个',
          value: '192,000',
        },
      ]
    },
    {
      title: '绿色处置',
      content: [
        '万物新生重视产品的全生命周期管理，对回收存在质量问题或款式废旧且无市场流通价值的设备，主动承担起产品末端处置的责任，与具有再生资源回收经营资质的第三方合作，对机器进行环保拆解，降低废弃产品中重金属对环境的危害。',
      ],
      subTitle: '2023年负责任地回收并监督处置',
      units: [
        {
          name: '交易和处置二手商品',
          unit: '万台',
          value: '3,230',
        },
        {
          name: '环保拆解废旧电子设备	',
          unit: '万台',
          value: '11.17',
        },
        {
          name: '减少电子产品污染',
          unit: '吨',
          value: '17.83',
        },
      ]
    }
  ],
  operation: {
    title: '绿色办公',
    content: '万物新生集团制定了通过制定内部政策明确遵循低成本、高品质、无污染的采购原则，确保员工拥有健康绿色的办公环境。同时充分挖掘集团自身特色，为员工办公配置了循环利用的二手电子产品(如笔记本、显示器)，鼓励员工采购二手商品，倡导绿色消费。此外，积极开展企业间绿色合作，引导公众低碳环保的消费行为，助力循环经济发展。',
    grid: [
      {
        image: ossImg('/esg/green-operation1.png'),
        title: "员工办公用电脑",
      },
      {
        image: ossImg('/esg/green-operation2.png'),
        title: "陶氏、立白与万物新生联手打造塑料包装回收闭环",
      },
      {
        image: ossImg('/esg/green-operation3.png'),
        title: "万物新生联手李宁、COSTA 推出限定环保 T 恤",
      },
    ],
  }
})

const US = () => ({
  seo: {
    title: 'ESG Green Development - ATRenew',
    keywords: 'ATRenew、RERE、AiHuiShou、PJT、Paipai',
    description: 'ATRenew redefines life cycle of consumer electronics through recycling and reuse, so as to promote sustainability.',
  },
  breadcrumb: 'Home / ESG / Green Development',
  banner: getValue({
    large: ossImg('/esg/overall-banner.png'),
    small: ossImg('/esg/esg-m-banner.png'),
  }),
  paragraphs: [
    {
      title: 'Green Development',
      content: [
        'ATRenew has redefined the life cycle of consumer electronic products through recycling. Throughout the past decade, we have never ceased to leverage the reuse of electronic products to promote sustainability and reduce carbon emissions across the entire industry.',
        'We regard the national pledge concerning emissions peak and carbon neutrality as our responsibility, as well as an opportunity for industry development. Thus, we endeavor to build a greener supply chain with a lower carbon footprint. While becoming a leading carbon-neutral company and a benchmark of carbon footprint management in the industry, we will use our influence across the supply chain to inspire a wider range of business partners to help the country reach carbon neutrality.',
      ],
      subTitle: '',
      units: [],
    },
    {
      title: 'Green Packaging',
      content: [
        'ATRenew adheres to low-carbon philosophy in daily operations, purchasing more environmentally friendly and durable packaging materials for standard, green, circular, and reduced packaging.'
      ],
      subTitle: 'Reused packaging materials in 2023',
      units: [
        {
          name: 'B2B business packaging fillers',
          unit: 'tons',
          value: '24',
        },
        {
          name: 'B2C business outer packaging components',
          unit: 'units',
          value: '192,000',
        }
      ]
    },
    {
      title: 'Green Disposal',
      content: ['ATRenew is devoted to the full life cycle management of products and proactively assumes responsibility for the end-of-life disposal of recycled devices with minimal use value. Partnering with third-party qualified operators, we ensure to dismantle such products in a compliant and environmentally-friendly manner and minimize the potential negative impact of heavy metals.'],
      subTitle: 'Responsible Recycling and Green Disposal in 2023',
      units: [
        {
          name: 'Devices responsibly transacted and disposed',
          unit: '',
          value: '32.3 million',
        },
        {
          name: 'Devices dismantled through eco-friendly means',
          unit: '',
          value: '111,700',
        },
        {
          name: 'Tons of electronic pollution reduction',
          unit: '',
          value: '17.83',
        }
      ]
    }
  ],
  operation: {
    title: 'Green Office',
    content: 'We provide our employees with recycled electronic office appliances. Also, we actively introduce environmentally friendly products to our peer companies and encourage the public to pursue a sustainable, low-carbon, and environmentally-conscious consumption lifestyle. Each of these activities contributes to the circular economy.',
    grid: [
      {
        image: ossImg('/esg/green-operation1.png'),
        title: "Pre-owned laptops for ATRenew employees",
      },
      {
        image: ossImg('/esg/green-operation2.png'),
        title: "Dow, Liby and ATRenew collaborate within the closed-loop of plastic packaging recycling",
      },
      {
        image: ossImg('/esg/green-operation3.png'),
        title: "ATRenew launches limited crossover T-shirts with Li-Ning and COSTA",
      },
    ],
  },
})

const data = {
  CN,
  US
}
export default data
